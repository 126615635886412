import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
    <article className="card ">
      <Link to={post.frontmatter.path}>
        <div className="card-image">
          {/* <Link to={post.frontmatter.path}> */}
            {!!post.frontmatter.thumbnail && (
              <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title + "- Featured Shot"} />
            )}
          {/* </Link> */}
        </div>
        <div className="card-content">
          <header>
            <h2 className="post-title">
              <Link to={post.frontmatter.path} className="post-link">
                {post.frontmatter.title}
              </Link>
            </h2>
            <p className="post-description">
              {post.frontmatter.metaDescription}
              {console.log(post.frontmatter)}
            </p>
            {/* <Link to={post.frontmatter.path}> */}
            <p className="learn-more-link">
              Learn More &rarr;  
            </p>  
            {/* </Link> */}
            {/* <div className="post-meta">{post.frontmatter.date}</div> */}
          </header>
        </div>
      </Link>
    </article>
)
export default PostLink
